<template>
    <div class="save-carrier-wrapper">

        <div v-if="isLoading(['AddEditUserPercent', 'GetUsersWithPermission'])" class="spinner-flex"><Spinner/></div>


        <div class=" grid row-15" v-if="_item">
            <InputDropDownList placeholder="Select user" name="User" v-model="_item.user" :error="errors.user" :showAttribute="(user) => { return user.name + ' ' + user.lastname }" selectAttribute="_id" :options="users"/>
            <Input name="Percent" v-model="_item.percent" :error="errors.percent" placeholder="Enter percent"/>
        </div>

    </div>
</template>

<script>
    export default {
        props: ['modal', 'carrier', "item"],
        data() {
            return {
                _item: this.item || {},
                errors: {},
                users: []
            }
        },
        methods: {
            getListOfUsers() {
                if(!this.carrier) return;
                this.ajax('GetUsersWithPermission', {
                    url: `/users/permissions?name=Loads`,
                    method: 'GET',
                }, (err, body) => {
                    if(!err) {
                        this.users = body;
                    }
                });
            },
            saveCharge() {

                this.ajax('AddEditUserPercent', {
                    url: '/dispatch/carrier/users/percent',
                    method: 'POST',
                    data: {
                        ...this._item,
                        carrier: this.carrier,
                    }
                }, (err, body) => {
                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else this.$ShowAlert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }
                    this.modal.close();
                });

            }
        },
        mounted() {
            this.getListOfUsers();
            if(this.modal) {
                this.modal.onConfirm(() => {
                    this.saveCharge();
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
.save-carrier-wrapper {
    padding: 20px;
    position: relative;
}

.spinner-flex {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    background: rgba(255,255,255,0.5);
    z-index: 3;
}
</style>