<template>
    <div class="wrapper grid row-50">
        
        <ActionBar title="Carriers">
            <Button text="Add Carrier" size="small" @click="saveCarrier({})"/>
        </ActionBar>


        <div class="roles-content grid row-20">

            <PageSearchInput v-model="_pageManager.query.search" placeholder="Search carriers..."/>

            <div class="page-filters">
                <div class="filter-item">
                    <div class="filter-item-name">Status</div>
                    <DropDownList v-model="_pageManager.query.status" placeholder="All" selectAttribute="value" showAttribute="name" :options="[{ value: null, name: 'All' }, { value: 'active', name: 'Active' }, { value: 'removed', name: 'Removed' }]"/>
                </div>
            </div>

            <div class="item-list">
                <TableView :head="tableHead" :size="columnsSizes" v-if="(_pageManager.list.length > 0)" :loading="isLoading(['GetCarriers'])">
                    <TableRow 
                        :size="columnsSizes" 
                        :key="item._id" 
                        v-for="(item, key) of _pageManager.list" 
                        @dblclick="saveCarrier(item, key)"
                        :menu="getCarrierMenu(item)"
                        @edit="saveCarrier(item, key)"
                        @remove="removeCarrier(item)"
                        @restore="restoreCarrier(item)"
                    >
                        <CarrierItem :item="item"/>
                    </TableRow>
                </TableView>

                <div v-else>
                    <div v-if="isLoading(['GetCarriers'])" class="spinner-flex"><Spinner/></div>
                    <div v-else><NoDataMessage text="Nothing found"/></div>
                </div>
            </div>

            <div class="pagination">
                <Pagination :manager="_pageManager" @pageChange="(page) => { _pageManager.query.page = page }"/>
            </div>
            
        </div>
        
    </div>
</template>

<script>
import PaginationJS from '../../mixins/Pagination'
import Pagination from '../../mixins/Pagination/components/Pagination.vue'
import CarrierItem from '../../components/Carriers/CarrierItem.vue'

import SaveCarrierView from '../../components/Carriers/SaveCarrierView.vue'

    export default {
        mixins: [PaginationJS],
        components: {
            Pagination,
            CarrierItem
        },
        data() {
            return {
                tableHead: [
                    { name: 'Company name' },
                    { name: 'MC #' },
                    { name: 'DOT #' },
                    { name: 'Contact name' },
                    { name: 'Contact phone' },
                    { name: 'Drivers' },
                    { name: 'Dispatch email' },
                    { name: 'Status' },
                    { name: '' },
                ],
                columnsSizes: [ 'minmax(50px, 1fr)','minmax(50px, 1fr)','minmax(50px, 1fr)','minmax(50px, 1fr)','minmax(50px, 1fr)','minmax(50px, 100px)','minmax(50px, 1fr)','minmax(50px, 120px)','34px' ],
                roles: []
            }
        },
        watch: {
            "_pageManager.query.search": {
                deep: true,
                handler() {
                    this._pageManager.query.page = 1;
                }
            },
            "_pageManager.query": {
                deep: true,
                handler() {
                    this.getCarriers();
                }
            }
        },
        methods: {
            getCarrierMenu(item) {
                let array = [{ name: 'Edit carrier', emit: 'edit' }];
                
                if(item.removed) {
                    array.push({ name: 'Restore carrier', emit: 'restore' })
                } else {
                    array.push({ name: 'Remove carrier', emit: 'remove', type: 'remove' })
                }

                return array;
            },
            getCarriers() {
                let query = new URLSearchParams(this._pageManager.query).toString();

                this.ajax('GetUsers', {
                    url: '/dispatch/carriers?' + query,
                    method: 'GET',
                }, (err, body) => {
                    if(!err) {
                        this._pageManager.pages = body.pages;
                        this._pageManager.count = body.count;
                        this._pageManager.list = body.list;
                        this._pageManager.page = body.page;
                        this._pageManager.hasNextPage = body.hasNextPage;
                        this._pageManager.hasPrevPage = body.hasPrevPage;
                    }
                });
            },
            async removeCarrier(item) {
                let confirmRemove = await this.$ShowConfirm('Do you really wish to remove this carrier?');
                if(!confirmRemove) return;

                await this.ajax('RemoveCarrier', {
                    url: `/dispatch/carriers`,
                    method: 'DELETE',
                    data: {
                        _id: item._id
                    }
                }, (err, body) => { });

                item.removed = true;
                this.getCarriers();
            },
            async restoreCarrier(item) {
                let confirmRemove = await this.$ShowConfirm('Do you really wish to restore this carrier?');
                if(!confirmRemove) return;

                await this.ajax('RestoreCarrier', {
                    url: `/dispatch/carriers/restore`,
                    method: 'PUT',
                    data: {
                        _id: item._id
                    }
                }, (err, body) => { });

                item.removed = false;
                this.getCarriers();
            },
            saveCarrier(carrier = {}) {

                carrier = JSON.parse(JSON.stringify(carrier));

                let modal = this.$ShowModal({
                    title: carrier._id ? 'Update Carrier' : 'Add Carrier',
                    description: carrier._id ? 'Update carrier details' : 'Enter carrier details',
                    component: SaveCarrierView,
                    props: {
                        item: carrier,
                        errors: {}
                    }
                });

                modal.onClose(() => {
                    this.getCarriers();
                });

                modal.onConfirm(async (props) => {
                    modal.processing(true);

                    await this.ajax('SaveCarrier', {
                        url: '/dispatch/carriers',
                        method: 'POST',
                        data: props.item
                    }, (err, body) => {
                        if(err) {
                            if(body.errors) modal.setProp('errors', body.errors);
                            else this.$ShowAlert(body.message || 'Something went wrong. Please, try again!');
                            return;
                        }
                        modal.close();
                        if(!props.item._id) {
                            this.saveCarrier(body);
                        }
                    });

                    modal.processing(false);
                });
            }
        },
        mounted() {
            this.getCarriers();
        }
    }
</script>

<style lang="scss" scoped>
.item-list {
    border-bottom: 1px solid $borderColor;
    padding: 0 0 20px 0;
}

.page-filters {
    display: flex;
    justify-content: flex-start;
    gap: 30px;
}
</style>