<template>
    <div class="save-carrier-wrapper ">

        <div v-if="isLoading(['GetCarrierDetails'])" class="spinner-flex">
            <Spinner />
        </div>

        <div class=" grid row-15" v-if="item">
            <h3>Main details</h3>
            <div v-if="item._id">
                <a href="" @click.prevent="manageChargeTypes">View charge types</a>
                &nbsp;&nbsp;
                <a href="" @click.prevent="manageUsersPercents">Users custom percent</a>
            </div>
            <Input name="Company name" v-model="item.companyName" :error="errors.name"
                placeholder="Enter company name" />
            <Input name="MC #" v-model="item.mc" :error="errors.mc" placeholder="Enter MC #" />
            <Input name="DOT #" v-model="item.dot" :error="errors.dot" placeholder="Enter DOT #" />
            <Input name="Dispatch email address" v-model="item.dispatchEmail" :error="errors.dispatchEmail"
                placeholder="Enter dispatch email address"
                description="Dispatch email address is used to send rate confirmations and other documents." />
            <Input name="Invoice email address" v-model="item.invoiceEmail" :error="errors.invoiceEmail"
                placeholder="Enter invoice email address"
                description="Invoice email address is used to send invoices." />
            <Input name="Contact name" v-model="item.contactName" :error="errors.contactName"
                placeholder="Enter contact name" />
            <Input name="Contact phone" v-model="item.contactPhone" :error="errors.contactPhone"
                placeholder="Enter contact phone" />

            <div class="input">
                <div class="_header">
                    <label>Address</label>
                    <span class="_error">{{ errors.address }}</span>
                </div>

                <div class="address-holder">
                    <AddressSearch v-model="item.address" />
                </div>
            </div>

        </div>

        <div class=" grid row-15" v-if="item">
            <h3>Referral</h3>
            <InputDropDownList placeholder="Select referral" name="Referral user" v-model="item.referralId" :error="errors.referralId" :showAttribute="(user) => { return `${user.fullName} (${user.email})` }" selectAttribute="_id" :options="referrals"/>
        </div>

    </div>
</template>

<script>
import ChargeTypesVue from './ChargeTypes.vue';
import CarrierUsersPercents from './Users/CarrierUsersPercents.vue';
import AddressSearch from './AddressSearch.vue'

export default {
    components: {
        AddressSearch
    },
    props: ['modal', 'item', 'errors'],
    data() {
        return {
            referrals: []
        }
    },
    methods: {
        getListOfReferrals() {
            this.ajax('GetListOfReferrals', {
                url: `/referrals/all`,
                method: 'GET',
            }, (err, body) => {
                if(!err) {
                    this.referrals = body;
                }
            });
        },
        getCarrierDetails() {
            if (!this.item._id) return;
            this.ajax('GetCarrierDetails', {
                url: `/dispatch/carriers/${this.item._id}`,
                method: 'GET',
            }, (err, body) => {
                if (err) {
                    this.$ShowAlert(body.message || 'Unfortunately, something went wrong.');
                    this.modal.close();
                    return;
                }
                this.modal.setProp('item', body);
            });
        },
        manageChargeTypes() {
            if (!this.item._id) return;
            let modal = this.$ShowModal({
                title: 'Carrier Charge Types',
                description: 'Add, edit or delete charge types.',
                component: ChargeTypesVue,
                props: {
                    carrier: this.item._id
                }
            });
        },
        manageUsersPercents() {
            if (!this.item._id) return;
            let modal = this.$ShowModal({
                title: 'Manage users custom percent',
                description: `Add, edit or delete user percent.`,
                component: CarrierUsersPercents,
                props: {
                    carrier: this.item._id
                }
            });
        }
    },
    mounted() {
        this.getCarrierDetails();
        this.getListOfReferrals();
    }
}
</script>

<style lang="scss" scoped>
.save-carrier-wrapper {
    padding: 20px;
    position: relative;
    display: grid;
    gap: 20px;
}

.spinner-flex {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    background: rgba(255, 255, 255, 0.5);
    z-index: 3;
}

.address-holder {
    padding: 20px;
    border: 1px solid $borderColor;
    box-shadow: $boxShadow;
    border-radius: 10px;
}
</style>