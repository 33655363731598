<template>
    <div class="location-search-wrapper grid gap-15">

        <div class="input">
            <div class="_header"><label class="label">Search</label></div>
            <div class="input input-search-box"  :class="{ 'active' : shown }" v-click-outside="hideResults">
                <input type="text" class="input-field" v-model="search" @keyup.enter="searchForLocations" @focus="shown = true" placeholder="Search for an address">
                <div class="search-box-holder" v-if="shown & results.length > 0">
                    <div class="results-list">
                        <div class="resultItem" :key="key" v-for="(item, key) of results" @click="selectItem(item)">
                            <strong class="resultName">{{ item.structured_formatting.main_text }}</strong>
                            <small class="resultAddress">{{ item.structured_formatting.secondary_text }}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Input name="Street" v-model="_address.street" placeholder="Street" />

        <div class="grid cols-3 gap-15">
            <Input name="City" v-model="_address.city" placeholder="City" />
            <InputDropDownList name="State" v-model="_address.state" selectAttribute="abbreviation" showAttribute="abbreviation" :options="US_STATES"/>
            <Input name="ZIP" v-model="_address.zip" placeholder="ZIP"/>
        </div>

        <div class="loader-holder" v-if="isLoading([id])"></div>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

    export default {
        props: {
            modelValue: { required: true }
        },
        data() {
            return {
                search: '',
                results: [],
                shown: false,
                _address: this.modelValue || {},
                id: uuidv4()
            }
        },
        watch: {
            modelValue() {
                this._address = this.modelValue || {}
            },
            _address: {
                deep: true,
                handler() {
                    this.$emit('update:modelValue', this._address);
                }
            }
        },
        methods: {
            hideResults() {
                this.shown = false;
                this.results = [];
            },
            searchForLocations() {
                if(!this.search) return;
                this.shown = true;
                this.ajax('HandleSearchForLocations', {
                    method: 'GET',
                    url: `/dispatch/google-maps/search/${this.search}`,
                }, (err, body) => {
                    if(!err) this.results = body;
                });
            },
            selectItem(item) {
                this.hideResults();
                let place_id = item.place_id;
                if(item.types && item.types.includes('establishment')) {
                    this._address.name = item.structured_formatting.main_text;
                } else {
                    this._address.name = '';
                }

                this.ajax(this.id, {
                    method: 'GET',
                    url: `/dispatch/google-maps/place/${place_id}`
                }, (err, body) => {
                    if(!err) {
                        this._address = {
                            ...this._address,
                            ...body
                        }
                    }
                });
            }
        },
    }
</script>

<style lang="scss" scoped>
.location-search-wrapper {
    position: relative;
    .loader-holder {
        background: rgba(255,255,255,0.5);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }
}

.input-search-box {
    position: relative;
    z-index: 1;
    &.active {
        z-index: 2;
    }
    .search-box-holder {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background: $themeColor1;
        border: 1px solid $borderColor;
        transform: translateY(5px);
        border-radius: 7px;
        overflow: hidden;
    }
}

.resultItem {
    padding: 10px;
    cursor: pointer;
    .resultName {
        font-weight: bold;
        font-size: 14px;
    }
    .resultAddress {
        font-size: 12px;
        opacity: 0.7;
        margin-left: 5px;
    }
    &:hover {
        background: $borderColor;
    }
}
</style>