<template>
    <div class="content-wrapper">
        <div v-if="isLoading(['GetCarrierChargeTypes', 'DeleteCarrierChargeTypes'])" class="spinner-flex"><Spinner/></div>
        <div class="grid row-20">

            <div>
                <div v-if="items.length > 0">
                    <div class="option header">
                        <div class="name">Type name</div>
                        <div class="percent">Percent</div>
                        <div></div>
                    </div>

                    <div class="option" :key="opt._id" v-for="(opt, key) of items">
                        <div class="name text-overflow"><a href="" @click.prevent="saveChargeType(opt)">{{ opt.name }}</a></div>
                        <div class="percent">{{ opt.percent }}%</div>
                        <div><button class="remove" @click="deleteChargeType(opt, key)"><i class="fa-solid fa-trash-can"></i></button></div>
                    </div>
                </div>
                <div v-else>
                    <NoDataMessage style="padding: 20px 0;" text="No charge types added yet"/>
                </div>
            </div>

            <div class="create-charge-link"><a href="" @click.prevent="saveChargeType">Create charge type</a></div>

        </div>
    </div>
</template>

<script>
import SaveChargeType from './SaveChargeType.vue'

    export default {
        props: ['modal', 'carrier'],
        data() {
            return {
                items: []
            }
        },
        methods: {
            getChargeTypes() {
                if(!this.carrier) return;
                this.ajax('GetCarrierChargeTypes', {
                    url: `/dispatch/carriers/chargeTypes/${this.carrier}`,
                    method: 'GET',
                }, (err, body) => {
                    if(!err) {
                        this.items = body;
                    }
                });
            },
            saveChargeType(item = {}) {
                let modal = this.$ShowModal({
                    title: item._id ? 'Edit Charge Type' : 'Create Charge Type',
                    description: 'Enter name and charge percent',
                    component: SaveChargeType,
                    props: {
                        carrier: this.carrier,
                        item: JSON.parse(JSON.stringify(item))
                    }
                });

                modal.onClose(() => {
                    this.getChargeTypes();
                });
            },
            async deleteChargeType(opt, key) {
                let confirmRemove = await this.$ShowConfirm('Do you really wish to remove this charge type?');
                if(!confirmRemove) return;

                await this.ajax('DeleteCarrierChargeTypes', {
                    url: `/dispatch/carriers/chargeTypes`,
                    method: 'DELETE',
                    data: {
                        _id: opt._id
                    }
                }, (err, body) => { });
                this.items.splice(key, 1);
                this.getChargeTypes();
            }
        },
        mounted() {
            this.getChargeTypes();
            if(this.modal) {
                this.modal.onConfirm(() => {
                    this.modal.close();
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
.content-wrapper {
    padding: 20px;
    position: relative;
}


.spinner-flex {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    background: rgba(255,255,255,0.5);
    z-index: 3;
}

.create-charge-link {
    margin: 0 auto;
}

.option {
    display: grid;
    align-items: center;
    grid-template-columns: minmax(0, 1fr) 120px 24px;
    padding: 10px 0;
    border-bottom: 1px solid $borderColor;
    &:last-child {
        border: 0;
    }
    &.header {
        font-weight: 500;
    }
    .percent {
        color: $textShade;
        text-align: center;
    }
    .remove {
        border: 1px solid $borderColor;
        border-radius: 6px;
        height: 24px;
        width: 24px;
        font-size: 10px;
        color: $error;
        background: $themeColor1;
        cursor: pointer;
        transition: ease 0.3s;
        &:hover {
            background: $error;
            color: #fff;
        }
    }
}
</style>